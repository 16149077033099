<template>
  <b-table :items="groups" :fields="TableFields">
    <template #cell(id)="data">
      <b-dropdown
        class="p-0 m-0 d-flex"
        size="md"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <fa-icon size="1x" icon="bars" />
        </template>
        
        <b-dropdown-item
          title="Detalhes"
          @click="$router.push(`/grupos/${data.item.id}/editar`)"
          >Editar</b-dropdown-item
        >
      </b-dropdown>
    </template>
    <template #cell(active)="data">
        <b-badge variant="success" pill v-if="data.item.active == '1'">Ativo</b-badge>
        <b-badge variant="warning" pill v-if="data.item.active == '0'">Inativo</b-badge>
    </template>
  </b-table>
</template>

<script>
import TableFields from "../schemas/TableListFields";
export default {
  data() {
    return {
      TableFields,
    };
  },
  computed: {
    groups() {
      return this.$store.state.groups.groups;
    },
  },
  mounted() {
    this.$store.dispatch("groups/groups_init");
  },
};
</script>

<style>
</style>